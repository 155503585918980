import GatsbyImage from 'gatsby-image'
import React, { useRef, FC, useEffect, useMemo, useState, Attributes } from 'react'
import { Event } from '../../data/event'

const Client: FC<Event['references']['slider'][0]> = ({ logo: { childImageSharp }, alt, url }) => (
  /^https?:\/\//gi.test(url) ?
    <a className="block mx-16 my-12" href={url} rel="noopener noreferrer" target="_blank">
      <GatsbyImage alt={alt || ''} loading="eager" fixed={childImageSharp.fixed} />
    </a>
    :
    <div className="mx-16 my-12">
      <GatsbyImage alt={alt || ''} loading="eager" fixed={childImageSharp.fixed} />
    </div>
)

const renderSliderItem = (key: Attributes["key"], children: React.ReactNode) => <div key={key}>{children}</div>

const References = ({ items }: { items: Event['references']['slider'] }) => {
  const slides = useMemo(() => items.map((item, i) => renderSliderItem(i, <Client {...item} />)), [items])
  const sliderTrack = useRef<HTMLDivElement>(null)

  const [cloneCount, setCloneCount] = useState(0)
  const [itemsWidth, setItemsWidth] = useState(0)

  useEffect(() => {
    if (sliderTrack && sliderTrack.current) {
      const content = sliderTrack.current.clientWidth
      setItemsWidth(content)
    }
  }, [slides, sliderTrack])

  // FIXME: recalculate after window resize
  useEffect(() => {
    if (sliderTrack && sliderTrack.current) {
      const w = sliderTrack.current.parentElement!.clientWidth
      const n = sliderTrack.current.children.length

      let clones_w = 0, clones = 0
      while (clones_w < w) {
        clones_w += (sliderTrack.current.children[clones % n] as HTMLElement).offsetWidth
        clones++
      }

      setCloneCount(clones)
    }
  }, [slides, sliderTrack])

  const clones = useMemo(() => new Array(cloneCount).fill(0).map((_, i) => renderSliderItem(i, <Client {...(items[i % items.length])} />)), [cloneCount, items])

  // Transition:
  // - from: translateX(0px)
  // - to:   translateX(- sliderTrack.current.clientWidth px)

  // const styles: React.CSSProperties = {
  //   transitionDuration: '10000ms',
  //   transform: `translateX(-${itemsWidth}px)`
  // }


  useEffect(() => {
    if (sliderTrack && sliderTrack.current && itemsWidth > 0) {
      const animation = sliderTrack.current.animate(
        [
          { transform: 'translateX(0px)' },
          { transform: `translateX(-${itemsWidth}px)` }
        ],
        {
          duration: 10 * itemsWidth, // moves with 100 px/s
          iterations: Infinity
        }
      )
      return () => animation.cancel()
    }
  }, [sliderTrack, itemsWidth])

  return (
    <div className="relative overflow-hidden select-none">
      <div ref={sliderTrack} className="flex flex-row w-min will-transform">{slides}{clones}</div>
    </div>
  )
}

export default References
