import React, { FC, Fragment } from 'react'
import ContactForm from '../components/ContactForm'
import References from '../components/event/references'
import Testimonials from '../components/event/testimonials'
import EventVideo from '../components/event/video'
import Funnel from '../components/funnel'
import { NavigationItem, NavPageItem } from '../components/Navigation'
import SEO from '../components/seo/seo'
import Tabs, { Tab } from '../components/Tabs'
import { useEvent } from '../data/event'
import CoronaBubble from '../design/CoronaBubble'
import H from '../design/H'
import Layout from '../layout'
import classnames from 'classnames'

const FAQ_ILLUS = [
  'ablauf.svg',
  'zielgruppe.svg',
  'dauer.svg',
  'ort.svg',
  'inhalt.svg',
]
const FAQ_ILLU_PRICING = 'preise.svg'

const FAQ: FC<{
  faq_h: string
  faq_q: string
  faq_a?: string
  illu?: string
  after?: any
}> = ({ faq_h, faq_q, faq_a, illu, children, after }) => (
  <div className="container mx-auto px-4 py-8 md:flex items-start even:flex-row-reverse">
    <div className="w-48 mb-4 mx-4 md:inline-block">
      {illu ? (
        <img className="w-full" alt={`Icon for ${faq_h}`} src={illu} />
      ) : null}
    </div>
    <div className="md:max-w-xl">
      <H small onDark>
        {faq_h}
      </H>
      <h2 className="font-medium mb-2">— {faq_q}</h2>
      {faq_a ? <p>{faq_a}</p> : null}
      {children}
    </div>
    {after}
  </div>
)


const EventPage = () => {
  const event = useEvent()

  const navPages: NavPageItem[] = [{ label: 'Spiele', path: '/' }]

  const navItems: NavigationItem[] = [
    { id: 'impressions', label: 'Impressionen' },
    { id: 'testimonials', label: event.testimonials.nav },
    { id: 'faqs', label: 'FAQs' },
    { id: 'contact', label: 'Interesse?' },
  ]

  // "Impressionen"
  // "Impressionen vom Event"

  return (
    <Layout navPages={navPages} navItems={navItems}>
      <SEO />

      <div className="relative">

      {/* WE-MOVED overlay */}
      {/* <div className="z-50 absolute top-0 left-0 w-screen bottom-0 bg-white bg-opacity-80 backdrop-filter backdrop-blur">
        <div className="mx-auto mt-16 md:mt-24 text-center max-w-xl">
          <H center small className="mb-8">Wir haben unsere Teamchallenge an einen neuen Ort verlegt!</H>
          <a className="btn" href="https://www.theteamchallenge.org/" rel="noopener">Zur neuen Seite</a>
        </div>
      </div> */}

      {/* SECTION: Welcome */}
      {/* FIXME: overflow-hidden is a temporary fix */}
      {/* <div className="container mx-auto px-4 py-8 md:flex flex-row overflow-hidden"> */}
      <div className="overflow-hidden">
        <div className="container mx-auto px-4 py-8 lg:flex flex-row">
          <div>
            <H className="max-w-2xl">{event.title}</H>
            <p className="text-xl max-w-2xl my-6 text-gray-700 leading-relaxed">
              {event.intro}
            </p>
            <p className="mt-4 mb-8">
              <a
                href="#contact"
                className="inline-block text-center btn"
              >
                Get in touch!
              </a>
            </p>
          </div>
          {/* <div className="hidden lg:block"> */}
          <div className="">
            <CoronaBubble title={event.corona_bubble.h}>
              {event.corona_bubble.content}
            </CoronaBubble>
          </div>
        </div>
      </div>

      {/* SECTION: Corona-Callout */}
      {/* <div className="lg:hidden container mx-auto md:px-4 py-8 md:flex flex-row">
        <CoronaBubble title={event.corona_bubble.h}>{event.corona_bubble.content}</CoronaBubble>
      </div> */}

      <section id="references" className="relative">
        <div className="container mx-auto">
          <h3 className="text-center font-medium text-lg">{event.references.h}</h3>
        </div>

        <div className="my-12">
          <References items={event.references.slider} />
        </div>

        {/* <div className="h-4 text-green">
          <svg className="w-full h-full" preserveAspectRatio="none" viewBox="0 0 1437 24" fill="none">
            <path d="M1436.5 0L1135.5 24L0 10.5L1436.5 0Z" className="fill-current" />
          </svg>
        </div> */}
      </section>

      <div id="impressions" className="relative mb-20">
        <div className="absolute top-0 w-full h-full">
          <svg
            className="w-full h-full"
            preserveAspectRatio="none"
            viewBox="0 0 1440 1162"
          >
            <path
              transform="translate(0 -514) translate(-49)"
              fill="#FF6750"
              fillRule="nonzero"
              stroke="none"
              strokeWidth={1}
              d="M0.339 623.2L48.739 772.219 132.5 1428.1 342.1 1675.033 1494.1 1675.033 1500 754 1391 665 115 514.5z"
            />
          </svg>
        </div>
        <div className="relative pb-20">
          <div className="container mx-auto pt-20 px-2 sm:px-0">
            <div className="sm:mx-2">
              <Tabs>
                <Tab title={event.videos.tab_1.title}>
                  <EventVideo videoId={event.videos.tab_1.videoId} />
                </Tab>
                <Tab title={event.videos.tab_2.title}>
                  <EventVideo videoId={event.videos.tab_2.videoId} />
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="relative mt-8">
            <div className="absolute right-0 bottom-0">
              <svg width="273px" height="254px" viewBox="0 0 273 254">
                <g
                  transform="translate(-1167 -1275) translate(-49) rotate(-31 3220.486 -1413.38)"
                  fill="#17FFAA"
                  stroke="none"
                  strokeWidth={1}
                  fillRule="evenodd"
                >
                  <path d="M51 0L372 0 372 28 51 28z" />
                  <path d="M0 56L321 56 321 84 0 84z" />
                </g>
              </svg>
            </div>
            <div className="relative container mx-auto pt-20 pb-10 px-8 sm:px-0">
              <p className="mx-auto text-lg md:text-xl text-center max-w-4xl leading-relaxed">
                {event.impression}
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="container mx-auto text-center mb-12">
        <a
          href="#contact"
          className="inline-block text-center btn min-w-0"
        >Get in touch!</a>
      </section>

      <section id="testimonials" className="relative">
        <div className="container mx-auto">
          <H className="pt-12" center>{event.testimonials.h}</H>
          <p className="py-8 px-4 text-center md:w-4/5 text-lg mx-auto">{event.testimonials.desc}</p>

          <div className="mb-20 md:mx-8">
            <Testimonials quotes={event.testimonials.quotes} />
          </div>
        </div>
      </section>

      <section id="faqs" className="relative">
        <div className="absolute right-0 top-0">
          <svg width="151" height="275" viewBox="0 0 151 275" fill="none">
            <rect width="377.031" height="32" transform="matrix(-0.89825 -0.439485 -0.439485 0.89825 352.731 165.699)" fill="#00FF99" />
            <rect width="377.031" height="32" transform="matrix(-0.89825 -0.439485 -0.439485 0.89825 389.22 245.699)" fill="#00FF99" />
          </svg>
        </div>
        <div className="relative">
          {event.faq.map(({ faq_item }, i) => (
            <FAQ
              key={i}
              {...faq_item}
              illu={
                i >= FAQ_ILLUS.length ? undefined : `/svgs/event/${FAQ_ILLUS[i]}`
              }
            />
          ))}

          <FAQ
            faq_h={event.pricing.h}
            faq_q={event.pricing.q}
            illu={`/svgs/event/${FAQ_ILLU_PRICING}`}
            after={event.pricing.offer_active ? <img className="m-4 mt-24 self-center" src="/svgs/event/pricing_offer.svg" /> : null}
          >
            <p>{event.pricing.before}</p>
            <div className="my-4 pt-2 pb-1 flex flex-wrap border-t border-b max-w-md">
              {event.pricing.prices.map(({ item: { label, price, price_offer } }, i) => {
                const offer = !!event.pricing.offer_active && !!price_offer
                return (
                  <Fragment key={i}>
                    <div className="w-1/2 mb-1">{label}</div>
                    <div className="w-1/2 mb-1">
                      <span className={classnames(offer && "line-through")}>{price}</span>
                      {offer && <span className="text-red font-semibold">&nbsp;{price_offer}</span>}
                    </div>
                  </Fragment>
                )
              })}
            </div>
            <p>{event.pricing.after}</p>
          </FAQ>
        </div>
      </section>

      <section id="contact" className="relative">
        <div className="absolute top-0 w-full h-full">
          <svg
            className="w-full h-full"
            preserveAspectRatio="none"
            viewBox="0 0 1440 364"
          >
            <path
              transform="translate(0 -2043)"
              fill="#FF6750"
              stroke="none"
              fillRule="evenodd"
              d="M143.684867 2405.79575L1106.9518 2373.79575 1469 2300 1511 2102 -41 2042 -28 2302z"
            />
          </svg>
        </div>

        <div className="container mx-auto relative">
          <div className="relative py-20 px-4 text-center">
            <Funnel className="mx-auto max-w-2xl flow-root pb-8 md:pb-2">
              {event.outro}
            </Funnel>

            <ContactForm formName="contact-teamevent" fieldConfig={{ teamsize: true }} inputClassNames="bg-opacity-25" />
          </div>
        </div>
      </section>

      </div>

    </Layout>
  )
}

export default EventPage
